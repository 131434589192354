import React from 'react'
import Layout from 'components/layout/Main/MainLayout'
import SEO from 'components/seo'

const TermsAndConditions = ({}) => {
  return (
    <Layout>
      <SEO title="Terms and Condition Page" />
      <section className="generic-section">
        <div className="container">
          <div className="title-section">
            <h1>Terms of Use</h1>
            <h4>
              MILLENNIAL HOME LENDING, INC. WEBSITE TERMS OF USE & DISCLAIMER
            </h4>
          </div>

          <div className="content-section">
            <p>
              These Terms of Use (“Terms”) cover your use and access to our
              website. Your agreement is with Millennial Home Lending, Inc.
              (“us” or “we”). Our Privacy Policy explains how we collect and use
              your information. By using our website, you are agreeing to be
              bound by these Terms and our Privacy Policy.
            </p>
            <p>
              Before accessing the website, please read these Terms. When you
              use our website, you agree to the Terms and they affect your
              rights and obligations. If you don’t want these Terms to apply,
              you may choose not to use our website. You must immediately leave
              the website and not return if you choose not to use our website.
              Otherwise, by accessing and using our website, you are agreeing to
              be bound to these Terms.{' '}
            </p>
            <h2>INFORMATION ABOUT YOUR USE OF THE WEBSITE</h2>
            <p>
              <b>Consent to Use Services.</b> If you are under the age of
              consent to form a binding agreement, you should only use our
              website if you are either an emancipated minor or have the legal
              consent or your parent or guardian for your use of our website.
              You should only use our website only if you are fully able to
              understand and enter and comply with these Terms.{' '}
            </p>
            <p>
              <b>Online Privacy Notice.</b> Your privacy is important to us. Our
              Privacy Policy provides information on our practices and the
              choices you can make about the way your information is collected
              and used by us.
            </p>
            <h4>THIRD-PARTY LINKS</h4>
            <p>
              {' '}
              <b>Third-Party Links.</b>
              This website may provide you with links to other websites on the
              internet. These websites may contain information or material that
              some people may find inappropriate or offensive. These third-party
              sites are not under our control and you acknowledge that (whether
              or not such websites are affiliated with us in any way) that we
              are no responsible for the accuracy, copyright compliance,
              legality, decency, or any other aspect of the content of such
              websites, and that your use of such websites is subject to their
              respective terms of use. The inclusion of a link to a third-party
              site does not imply our endorsement of any site.{' '}
            </p>
            <h4>LEGAL RESPONSIBILITIES</h4>
            <p>
              {' '}
              <b>Disclaimer of Warranties.</b>
              We make great efforts to make our website is error and
              interruption free; however, we cannot promise that such efforts
              will result with such desired performance. YOUR USE OF THIS
              WEBSITE IS AT YOUR OWN RISK. NO ORAL OR WRITTEN INFORMATION OR
              ADVICE GIVEN BY US SHALL CREATE A WARRANTY. THE WEBSITE AND ALL
              INFORMATION CONTAINED THEREIN, IS PROVIDED “AS IS” AND “AS
              AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
              LAW. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT
              THAT THE FUNCTIONS CONTAINED ON OR THROUGH THIS WEBSITE WILL BE
              AVAILABLE, UNINTERRUPTED, OR ERROR-FREE, THAT DEFECTS WILL BE
              CORRECTED, OR THAT THE SERVICE OR THE SERVERS THAT MAKE THE
              WEBSITE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
              WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
              THE RESULTS OF THE USE OF THE WEBSITE OR INFORMATION CONTAINED
              THEREIN OR ANY WEBSITE LINKED TO THIS WEBSITE IN TERMS OF THEIR
              CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. WE MAKE NO
              WARRANTIES THAT YOUR USE OF THE WEBSITE AND INFORMATION CONTAINED
              THEREIN WILL NOT INFRINGE THE RIGHTS OF OTHERS AS WE ASSUME NO
              LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH
              WEBSITE OR INFORMATION. IF APPLICABLE LAW DOES NOT ALLOW THE
              EXCLUSION OF SOME OR ALL OF THE ABOVE IMPLIED WARRANTIES TO APPLY
              TO YOU, THE ABOVE EXCLUSIONS WILL APPLY TO YOU ONLY TO THE EXTENT
              PERMITTED BY APPLICABLE LAW.
            </p>
            <p>
              <b>Limitation of Liability.</b>
              WE DISCLAIM ALL LIABILITY, WHETHER BASED ON CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, AND DO NOT
              ACCEPT ANY LIABILITY FOR ANY LOSS OR DAMAGE (DIRECT, INDIRECT,
              PUNITIVE, ACTUAL, CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY,
              OR OTHERWISE) RESULTING FROM ANY USE OF, OR INABILITY TO USE THIS
              WEBSITE OR THE INFORMATION CONTAINED THEREIN, REGARDLESS OF THE
              BASED UPON WHICH LIABILITY IS CLAIMED AND EVEN IF MHL HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT
              LIMITATION, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
              REPAIR, OR CORRECTION IN THE EVENT OF ANY SUCH LOSS OR DAMAGE
              ARISING THEREIN. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART
              OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE
              LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY
              APPLICABLE LAW. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE
              ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
            </p>

            <p>
              <b>California Residents.</b> IF YOU ARE A CALIFORNIA RESIDENT, YOU
              WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA CIVIL CODE SECTION
              1542, WHICH SAYS, “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
              WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR
              AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST
              HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
            </p>

            <h4>ADDITIONAL TERMS</h4>
            <p>
              {' '}
              <b>Updates to Terms.</b>
              We are always working on additional features to our website, and
              because of that and potential changes in the law, we may update
              these Terms from time to time, and in our sole discretion. When we
              do so, we will post the changes on this page and will update the
              effective date of the Terms on this page. You consent and agree to
              receive notices of update of these Terms through our posting of
              updated Terms. You should visit this page regularly to review the
              current Terms. If you do not agree to the updated Terms, you
              should stop using our website.
            </p>
            <p>This policy was last modified on 7/20/2020.</p>

            <h4>CONTACTING US</h4>
            <p>
              If there are any questions regarding these Terms, you may contact
              us using the information below.
            </p>
            <p>privacy@mhlending.us</p>
            <p>Millennial Home Lending, Inc.</p>
            <p>9200 Oakdale Ave. Ste. 501, Chatsworth, CA 91311</p>
            <p>(818) 812-5150 or (877) 274-2610</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsAndConditions
